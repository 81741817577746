import React, { Component } from "react";
import { Navbar, Alignment } from "@blueprintjs/core";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import { PasswordResetFirebase } from "../password-reset";
import { Homepage } from "../homepage";
import { NewAccount } from "../new-account";

export class Navigation extends Component {
  render() {
    return (
      <>
        <Router>
          <>
            <Navbar className="bp3-dark">
              <Navbar.Group align={Alignment.LEFT}>
                <Navbar.Heading>Pincanna Staff Support</Navbar.Heading>
                <Navbar.Divider />
                <Link className="bp3-button bp3-minimal" to="/">
                  Main Menu
                </Link>
              </Navbar.Group>
            </Navbar>

            <Route path="/pwd" component={PasswordResetFirebase} />
            <Route path="/nga" component={NewAccount} />
            <Route path="/" exact component={Homepage} />
          </>
        </Router>
      </>
    );
  }
}
