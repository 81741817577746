import React, { Component } from 'react';
import { Card, H1, Label, Button, Text } from '@blueprintjs/core';

export class PasswordResetFirebase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      tracking_id: null
    };
  }
  onSubmit = e => {
    let form = e.target;
    fetch('https://pincanna-express.firebaseapp.com/gsuite/passwords', {
      method: 'POST',
      body: JSON.stringify({
        username: form.elements.username.value,
        fullName: form.elements.name.value,
        contact_email: form.elements.contact_email.value
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(resp => resp.json())
      .then(data => {
        console.log(data);
        !data.error && this.setState({ submitted: true, tracking_id: data.tracking_id });
        data.error && alert(data.error);
      })
      .catch(error => console.error('Oops!', error));

    e.preventDefault();
  };
  render() {
    return (
      <>
        {this.state.submitted && (
          <>
            <H1>Your password reset request was successful.<br />The administrator has been notified.</H1>
            <p><strong>Tracking ID:</strong> {this.state.tracking_id}</p>
          </>
        )}
        {!this.state.submitted && (
          <Card className="container">
            <H1>Lost your Google password? Start here.</H1>
            <Text>
              PLEASE NOTE: You must use the recovery email that we have on file.
              <br />
              The request will fail if the recovery email is not in our records.
            </Text>
            <hr />
            <form onSubmit={this.onSubmit}>
              <div className="input-group">
                <Label>
                  Your Name
                  <input className="bp3-input form-control" name="name" />
                </Label>
                <Label>
                  Username
                  <input className="bp3-input form-control" name="username" />
                </Label>
                <Label>
                  Contact Email
                  <input className="bp3-input" name="contact_email" />
                </Label>
              </div>
              <Button type="submit" value="Submit">
                Submit
              </Button>
            </form>
          </Card>
        )}
      </>
    );
  }
}
