import React, { Component } from 'react';
import {
  Card,
  FormGroup,
  InputGroup,
  Button,
  RadioGroup,
  Radio,
  Tag,
  Divider,
  Intent,
  Callout,
  H4
} from '@blueprintjs/core';
import { firebaseConfig } from '../../config/firebase';
import * as firebase from 'firebase';
firebase.initializeApp(firebaseConfig);

export class NewAccount extends Component {
  state = {
    type: 'gsuite',
    fullName: '',
    username: '',
    authenticated: false,
    staff_name: null,
    staff_email: null,
    submitted: false,
    contact_email: '',
    errors: []
  };
  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          authenticated: true,
          staff_email: firebase.auth().currentUser.email,
          staff_name: firebase.auth().currentUser.displayName
        })
      }
    })

  }
  handleTypeChange = e => {
    this.setState({ type: e.target.value });
  };
  handleUsername = e => this.setState({ username: e.target.value });
  handleFullName = e => this.setState({ fullName: e.target.value });
  handleContactEmail = e => this.setState({ contact_email: e.target.value });
  login = () => {
    let provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ hd: 'pincanna.com' });
    provider.addScope('https://www.googleapis.com/auth/gmail.insert');
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(r => {
        this.setState({
          authenticated: true,
          staff_name: r.user.displayName,
          staff_email: r.user.email
        });
      })
      .catch(e => console.warn('Auth error:', e));
  };

  onSubmit = e => {
    if (
      this.state.username === '' ||
      this.state.fullName === '' ||
      this.state.type === '' ||
      this.state.contact_email === ''
    ) {
      this.setState({ errors: ['All fields are required.'] });
      e.preventDefault();
      return;
    }
    let body = JSON.stringify({
      fullName: this.state.fullName,
      username: this.state.username,
      contact_email: this.state.contact_email,
      staff_name: this.state.staff_name,
      staff_email: this.state.staff_email,
      type: this.state.type
    });

    fetch('https://pincanna-express.firebaseapp.com/gsuite/accounts', {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors'
    })
      .then(resp => resp.json())
      .then(data => {
        console.log('Fx data', data);
        firebase.auth().signOut().then(() => console.log('Signed out!'));
        this.setState({ submitted: true });
      })
      .catch(error => this.setState({ submitted: false }));
    e.preventDefault();
  };

  render() {
    return (
      <>
        <div className="container">
          <h1>New GSuite Account</h1>

          {!this.state.authenticated && (
            <>
              <p>You must be a Pincanna staff member to use this page.</p>
              <Button onClick={this.login}>Authenticate with Pincanna GSuite</Button>
            </>
          )}
          {this.state.submitted && <h2>Success! The administrator has received your request.</h2>}
          {this.state.authenticated && !this.state.submitted && (
            <Card>
              <Callout intent={Intent.PRIMARY}>
                <H4>Important</H4>
                This will request a new Google account within the Pincanna organization.
                <br />
                The request will need to be processed by the administrator before the account is
                created.
              </Callout>
              <Divider />
              {this.state.errors.length > 0 && (
                <ul>
                  {this.state.errors.map(err => (
                    <li>{err}</li>
                  ))}
                </ul>
              )}
              <form onSubmit={this.onSubmit}>
                <FormGroup label="Full Name" labelFor="full_name" labelInfo="(required)">
                  <InputGroup
                    id="full_name"
                    onChange={this.handleFullName}
                    value={this.state.full_name}
                    placeholder="First and Last Name"
                  />
                </FormGroup>
                <FormGroup
                  helperText="The full username for the account to be created"
                  label="Account username"
                  labelFor="username"
                  labelInfo="(required)"
                >
                  <InputGroup
                    id="username"
                    onChange={this.handleUsername}
                    value={this.state.username}
                    rightElement={<Tag>@pincanna.com</Tag>}
                  />
                </FormGroup>
                <FormGroup
                  helperText="This is where login information will be sent once the account has been created."
                  label="Contact Email"
                  labelFor="contact_email"
                  labelInfo="(required)"
                >
                  <InputGroup
                    id="contact_email"
                    value={this.state.contact_email}
                    onChange={this.handleContactEmail}
                  />
                </FormGroup>
                <RadioGroup
                  label="Account Type"
                  onChange={this.handleTypeChange}
                  selectedValue={this.state.type}
                >
                  <Radio label="GSuite + Cloud Identity" value="gsuite" />
                  <Radio label="Cloud Identity Only" value="cloud-identity" />
                </RadioGroup>
                <button type="submit" className="bp3-button">
                  Submit
                </button>
                <Divider />
                <p>
                  <Tag intent={Intent.PRIMARY}>
                    <strong>Authenticated as:</strong> {this.state.staff_name}
                  </Tag>
                </p>
              </form>
            </Card>
          )}
        </div>
      </>
    );
  }
}
