import React from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';

import '../node_modules/@blueprintjs/core/lib/css/blueprint.css';
import '../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css';

import { Navigation } from './components/navigation';

function App() {
  return (
    <div className="App">
      <Navigation />
    </div>
  );
}

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
