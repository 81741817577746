import React from 'react';
import { H1, H4, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

export class Homepage extends React.Component {
  render() {
    return (
      <>
        <div className="container">
          <H1>Main Menu</H1>
          <hr />
          <H4>What would you like to do?</H4>
          <ButtonGroup vertical={true}>
            <Link className="bp3-button bp3-intent-primary bp3-large" to="/pwd">
              Reset My Password
            </Link>
            <Link className="bp3-button bp3-intent-success bp3-large" to="/nga">
              Add a New GSuite User
            </Link>
            <a
              target="_blank"
              className="bp3-button bp3-large"
              href="https://accounts.google.com/o/saml2/initsso?idpid=C01rqzbcd&spid=716662480909&forceauthn=true&hd=pincanna.com"
            >
              Login to Wordpress
            </a>
          </ButtonGroup>
        </div>
      </>
    );
  }
}
